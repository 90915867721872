<template>
  <div
    class="page-cart px-4"
    :class="{
      settled: isSettled,
      skipped: isSkipped,
    }"
  >
    <div class="status">
      <div v-if="isSettled">
        {{ $t('bankStatements.statuses.single.settled') }}
      </div>
      <div v-else-if="isSkipped">
        {{ $t('bankStatements.statuses.single.skipped') }}
      </div>
    </div>

    <div class="d-flex align-items-center pt-4">
      <div class="bill-icon">
        <i class="fas fa-exchange-alt" />
      </div>
      <div class="title flex-grow-1">
        <div class="small text-secondary pb-1">
          {{ formatDate(transactionDate) }}
        </div>
        <div>
          {{ title }}
        </div>
        <div class="small py-1">
          {{ fromName }}
        </div>
        <div class="small text-secondary">
          {{ fromAccount | accountNumber }} <span v-if="toAccount"> -> {{ toAccount | accountNumber }}</span>
        </div>
      </div>
      <div
        class="text-right pl-2"
        style="width: 140px"
      >
        <div class="money-font text-nowrap text-primary">
          {{ formatCurrency(value, currency) }}
        </div>
        <div
          v-if="isUnsettled"
          class="text-secondary small"
          :class="{ 'text-danger': value - settled < 0 }"
        >
          {{ $t('payment.toSettle') }}:
          <span class="money-font pl-1 text-nowrap">
            {{ formatCurrency(value - settled, currency) }}
          </span>
        </div>
      </div>
    </div>
    <hr>
    <div
      v-if="isUnsettled || settleSkipped"
      class="d-flex justify-content-end"
    >
      <div
        class="flex-grow-1 mx-1"
        style="max-width: 250px"
      >
        <button
          class="btn btn-sm btn-block btn-danger"
          :disabled="pending"
          @click="skip"
        >
          <i class="fas fa-times pr-1" />
          {{ $t('bankStatements.transfer.skip') }}
        </button>
      </div>
      <div
        class="flex-grow-1 mx-1"
        style="max-width: 250px"
      >
        <button
          class="btn btn-sm btn-block btn-primary"
          :disabled="value != settled || pending"
          @click="settle"
        >
          <i class="fas fa-check pr-1" />
          {{ $t('bankStatements.transfer.settle') }}
        </button>
      </div>
    </div>
    <div
      v-else-if="status === 'Skipped'"
      class="d-flex justify-content-end"
    >
      <div
        class="flex-grow-1 mx-1"
        style="max-width: 250px"
      >
        <button
          class="btn btn-sm btn-block btn-primary"
          @click="settleSkipped = true"
        >
          <i class="fas fa-play pr-1" />
          {{ $t('bankStatements.transfer.startSettle') }}
        </button>
      </div>
    </div>
    <div
      v-else-if="status === 'Settled'"
      class="d-flex justify-content-end"
    >
      <div
        class="flex-grow-1 mx-1"
        style="max-width: 250px"
      >
        <button
          class="btn btn-sm btn-block btn-danger"
          :disabled="pending"
          @click="skip"
        >
          <i class="fas fa-times pr-1" />
          {{ $t('bankStatements.transfer.skip') }}
        </button>
      </div>
    </div>
    <div
      v-else
      class="text-danger small"
    >
      {{ $t('bankStatements.duplicateWarning') }}
    </div>
    <hr>
    <PaymentDetails
      v-if="ready && paymentId"
      :payment-id="paymentId"
      hide-header
      hide-remove
      :with-transfer="false"
    />
    <PaymentSettlementForm
      v-else-if="ready && (isUnsettled || settleSkipped)"
      :payment="value"
      :auto-settle="autoSettle"
      :currency="currency"
      :payers.sync="payersSettlement"
      @settled="settled = $event"
    />
  </div>
</template>

<script>
import PaymentDetails from '@/components/payments/PaymentDetails';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import PaymentSettlementForm from '../payments/PaymentSettlementForm';

export default {
  props: {
    id: String,
    value: Number,
    title: String,
    fromName: String,
    currency: String,
    fromAccount: String,
    toAccount: String,
    status: String,
    transactionDate: String,
    paymentId: String,
    autoSettle: Boolean,
  },
  data: () => ({
    settled: 0,
    payersSettlement: [],
    settleSkipped: false,
    ready: true,
    pending: false,
  }),
  components: {
    PaymentSettlementForm,
    PaymentDetails,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    isUnsettled() {
      return this.status === 'Unspecified' || this.status === 'Unsettled';
    },
    isSettled() {
      return this.status === 'Settled' || this.status === 'DuplicateSettled';
    },
    isSkipped() {
      return this.status === 'Skipped' || this.status === 'DuplicateSkipped';
    },
  },
  watch: {
    id() {
      this.ready = false;
      this.settleSkipped = false;
      this.suggest()
        .finally(() => {
          this.ready = true;
        });
    },
  },
  methods: {
    ...mapActions([
      'settleTransfer',
      'skipTransfer',
      'suggestTransferSettlement',
    ]),
    formatDate(date) {
      return moment(date).format('HH:mm DD/MM/YYYY');
    },
    skip() {
      if (this.status === 'Skipped') {
        this.settleSkipped = false;
        return;
      }

      this.pending = true;
      this.skipTransfer({
        params: {
          transferId: this.id,
          query: {
            transactionDate: this.transactionDate,
          },
        },
      })
        .then(() => {
          this.$emit('skipped');
        })
        .finally(() => {
          this.pending = false;
        });
    },
    settle() {
      this.pending = true;
      this.settleTransfer({
        params: {
          transferId: this.id,
          query: {
            transactionDate: this.transactionDate,
          },
        },
        data: {
          bills: this.payersSettlement.flatMap((x) => x.settlement.bills),
          overpayments: this.payersSettlement.flatMap((x) => x.settlement.overpayments),
          refunds: this.payersSettlement.flatMap((x) => x.settlement.refunds),
          donations: this.payersSettlement.flatMap((x) => x.settlement.donations),
        },
      })
        .then(({ data }) => {
          this.$emit('settled', data.paymentId);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    suggest() {
      if (!this.isUnsettled) return Promise.resolve();
      this.pending = true;
      return this.suggestTransferSettlement({
        params: {
          transferId: this.id,
          query: {
            transactionDate: this.transactionDate,
          },
        },
      })
        .then(({ data }) => {
          this.payersSettlement = data.payers.map((x) => ({
            ...x,
            settlement: {
              bills: [],
              overpayments: [],
              refunds: [],
              donations: [],
            },
          }));
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.ready = false;
    this.suggest()
      .finally(() => {
        this.ready = true;
      });
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1;
}

.page-cart {
  border: 2px solid transparent;
  border-radius: 20px;
  position: relative;
}

.settled {
  border-color: rgba($blue, 0.2);

  .status {
    background-color: $blue;
  }
}

.skipped {
  border-color: rgba($orange, 0.2);

  .status {
    background-color: $orange;
  }
}

.status {
  border-radius: 10px;
  color: white;
  font-size: 11px;
  font-weight: 400;
  width: 100px;
  text-align: center;
  position: absolute;
  top: -2px;
  left: -1px;
}
</style>
