<template>
  <div
    class="operation-wrapper"
    :class="{
      settlement: isSettlement,
      open: showDetails,
    }"
    :style="{
      'border-color': lightColor,
    }"
  >
    <div
      class="d-flex align-items-center audit-row"
      :style="{
        'background-color': lightColor,
      }"
      @click="isSettlement ? (showDetails = !showDetails) : null"
    >
      <div class="pl-1">
        <i
          :class="typeInfo.icon"
          :style="{ color: colors(typeInfo.color) }"
        />
      </div>
      <div class="pl-2">
        {{ typeInfo.text }}
      </div>
      <div class="flex-grow-1" />
      <div
        class="text-secondary text-right text-nowrap small pr-1"
      >
        {{ formatDateTime(timestamp) }}
      </div>
    </div>
    <div
      v-if="showDetails"
      class="p-2"
    >
      <div
        v-for="p in paymentPayers"
        :key="p.id"
      >
        <PaymentPayerSettlement
          :payer="p"
          :bills="bills"
          :payment-id="paymentId"
          :currency="parsedDetails.currency"
          :bill-settlement="parsedDetails.bills.filter(x => x.payerId === p.id)"
          :overpayment-settlement="parsedDetails.overpayments.filter(x => x.payerId === p.id)"
          :refund-settlement="parsedDetails.refunds.filter(x => x.payerId === p.id)"
          :donation-settlement="parsedDetails.donations.filter(x => x.payerId === p.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/utils/colors';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import { mapGetters } from 'vuex';
import PaymentPayerSettlement from '../PaymentPayerSettlement';

export default {
  props: {
    billingDay: String,
    operationDetails: String,
    operationType: String,
    paymentId: String,
    requesterId: String,
    timestamp: String,
    payers: Array,
    bills: Array,
  },
  data: () => ({
    showDetails: false,
  }),
  components: {
    PaymentPayerSettlement,
  },
  computed: {
    ...mapGetters(['formatMoney']),
    lightColor() {
      return tinycolor(colors(this.typeInfo.color)).setAlpha(0.1).toString();
    },
    isSettlement() {
      return this.operationType === 'PaymentSettledEvent';
    },
    parsedDetails() {
      try {
        return JSON.parse(this.operationDetails);
      } catch {
        return null;
      }
    },
    paymentPayers() {
      if (!this.isSettlement) {
        return [];
      }
      const payerIds = this.parsedDetails.bills.map((x) => x.payerId)
        .concat(this.parsedDetails.overpayments.map((x) => x.payerId))
        .concat(this.parsedDetails.refunds.map((x) => x.payerId))
        .concat(this.parsedDetails.donations.map((x) => x.payerId));

      return this.payers.filter((p) => payerIds.includes(p.id));
    },
    typeInfo() {
      switch (this.operationType) {
        case 'PaymentAddedEvent':
          return {
            icon: 'fas fa-circle-plus',
            text: 'Utworzono',
            color: 'green',
          };
        case 'PaymentSettledEvent':
          return {
            icon: 'fas fa-circle-dollar',
            text: 'Rozliczono',
            color: 'orange',
          };
        case 'PaymentDeletedEvent':
          return {
            icon: 'fas fa-circle-minus',
            text: 'Usunięto',
            color: 'red',
          };
        default:
          return {
            icon: 'fas fa-circle-question',
            text: '?',
          };
      }
    },
  },
  methods: {
    colors,
    tinycolor,
    formatDateTime(date) {
      return moment(date).format('HH:mm DD MMM YYYY');
    },
  },
};
</script>

<style lang="scss">

.operation-wrapper {
  margin-top: 10px;
  box-shadow: 1px 2px 3px rgba(#aaa, 0.2);
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
}

.audit-row {
  border-radius: 10px;
}

.settlement {
  .audit-row {
    cursor: pointer;
    border-width: 1px;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
