<template>
  <div
    v-if="details"
  >
    <PrintPayment
      :payment="details"
      :bills="bills || []"
      :payers="payers || []"
    />

    <div
      v-if="!hideHeader"
      class="d-flex align-items-center"
    >
      <div class="bill-icon">
        <i :class="paymentType ? paymentType.icon : 'fas fa-question'" />
      </div>
      <div class="title flex-grow-1">
        {{ details.title }}
        <div class="d-flex">
          <Tippy>
            <template #trigger>
              <div class="small text-secondary mt-1">
                {{ formatDate(details.timestamp) }}
              </div>
            </template>

            {{ $t('bill.settled') }}:
            <div class="font-weight-bold">
              {{ formatDate(details.created) }}
            </div>
          </Tippy>
        </div>
      </div>
      <div class="text-right">
        <div class="money-font">
          {{ formatCurrency(details.value, details.currency) }}
        </div>
        <div
          v-if="action === 'settlement'"
          class="text-secondary small"
          :class="{ 'text-danger': details.value - settled < 0 }"
        >
          {{ $t('payment.toSettle') }}:
          <span class="money-font pl-1">
            {{ formatCurrency(details.value - settled, details.currency) }}
          </span>
        </div>
      </div>
    </div>
    <SimpleTransferHeader
      v-if="transfer && withTransfer"
      class="mt-2"
      v-bind="transfer"
    />
    <hr v-if="!hideHeader">
    <template v-if="!audit">
      <div class="d-flex justify-content-between">
        <div
          v-if="!action"
          class="d-flex position-relative px-2"
        >
          <div class="pr-2">
            <div
              class="secondary-icon bill-button px-3"
              :class="{ selected: action === 'settlement' }"
              @click="select('settlement')"
            >
              <i class="fas fa-pen-to-square mr-2" />
              <span class="edit-details">
                {{ $t('payment.editSettlement') }}
              </span>
            </div>
          </div>
        </div>
        <div v-else />
        <div
          v-if="!action"
          class="d-flex"
        >
          <div class="px-2">
            <i
              v-tippy
              class="fas fa-clock-rotate-left secondary-icon"
              :content="$t('bill.changeHistory')"
              @click="toggleAudit"
            />
          </div>
          <div class="px-2">
            <i
              v-tippy
              class="fas fa-print secondary-icon"
              :content="$t('payment.print')"
              @click="togglePrint"
            />
          </div>
          <div
            v-if="!hideRemove"
            class="px-2"
          >
            <Tippy
              interactive
              arrow
              theme="light"
              trigger="click"
            >
              <template #trigger>
                <i
                  v-tippy
                  class="fas fa-trash secondary-icon"
                  :content="$t('general.remove')"
                />
              </template>

              <div v-if="!pendingRemove">
                <button
                  class="btn btn-block btn-sm btn-danger"
                  data-test="remove-bill-confirm"
                  @click="remove"
                >
                  <i class="fas fa-trash pr-2" />
                  {{ $t('general.confirmRemove') }}
                </button>
              </div>
              <div v-else>
                <Loader size="22px" />
              </div>
            </Tippy>
          </div>
        </div>
        <div
          v-else
          style="width: 500px"
        >
          <SaveButton
            :pending="pending"
            :disabled="details.value - settled < 0"
            @save="save"
            @cancel="action = ''; $emit('focus', false)"
          />
        </div>
      </div>
      <hr>
      <div
        v-if="!pending"
      >
        <div v-if="!action">
          <div
            v-for="(payer, i) in payers"
            :key="payer.id"
          >
            <PaymentPayerSettlement
              :payer="payer"
              :bills="bills"
              :payment-id="paymentId"
              :currency="details.currency"
              :bill-settlement="details.bills.filter(x => x.payerId === payer.id)"
              :overpayment-settlement="details.overpayments.filter(x => x.payerId === payer.id)"
              :refund-settlement="details.refunds.filter(x => x.payerId === payer.id)"
              :donation-settlement="details.donations.filter(x => x.payerId === payer.id)"
            />
            <hr v-if="i + 1 !== payers.length">
          </div>

          <div v-if="payers.length === 0">
            <div class="text-center pt-4 text-danger">
              <i
                class="fa-solid fa-clipboard-question pr-2"
                style="font-size: 40px"
              />

              <div class="font-weight-bold pt-2">
                {{ $t('payment.unsettled') }}
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="action === 'settlement'">
          <PaymentSettlementForm
            :payment="details.value"
            :payment-id="paymentId"
            :currency="details.currency"
            :date="toDateOnly(details.timestamp)"
            :settlement="payersSettlement"
            :payers.sync="payersSettlement"
            @settled="settled = $event"
          />
        </div>
      </div>
      <div
        v-else
        class="pending-details"
      />
    </template>

    <div v-if="audit">
      <div class="d-flex justify-content-between">
        <div
          class="d-flex justify-content-between align-items-center mt-1"
        >
          <h5 class="payer-section mb-0">
            {{ $t('bill.changeHistory') }}
          </h5>
        </div>

        <i
          class="fas fa-times secondary-icon"
          @click="audit = false"
        />
      </div>
      <hr class="mt-2">

      <PaymentsAudit
        :payment-id="paymentId"
        :payment-date="payment.billingDay"
      />
    </div>
  </div>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import SaveButton from '@/components/SaveButton';
import PrintPayment from '@/components/payments/PrintPayment';
import SimpleTransferHeader from '@/components/payments/SimpleTransferHeader';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import PaymentPayerSettlement from './PaymentPayerSettlement';
import PaymentSettlementForm from './PaymentSettlementForm';
import PaymentsAudit from './audit/PaymentsAudit';

export default {
  props: {
    paymentId: String,
    payment: Object,
    hideHeader: Boolean,
    hideRemove: Boolean,
    withTransfer: Boolean,
  },
  data: () => ({
    details: null,
    payers: null,
    bills: null,
    transfer: null,
    pending: false,
    pendingRemove: false,
    action: '',
    settled: 0,
    payersSettlement: [],
    print: false,
    modal: true,
    audit: false,
  }),
  components: {
    PaymentPayerSettlement,
    SimpleTransferHeader,
    PaymentSettlementForm,
    PrintPayment,
    PaymentsAudit,
    SaveButton,
  },
  computed: {
    ...mapGetters(['formatCurrency', 'paymentTypes']),
    paymentType() {
      if (!this.details) return null;
      return this.paymentTypes.find((y) => y.key === this.details.type);
    },
  },
  watch: {
    paymentId() {
      this.details = this.payment;
      this.request();
    },
    action() {
      this.resetSettlement();
    },
  },
  methods: {
    ...mapActions([
      'getPayment',
      'settlePayment',
      'deletePayment',
    ]),
    formatDate(date) {
      return moment(date).format('HH:mm DD/MM/YYYY');
    },
    toggleAudit() {
      this.audit = true;
    },
    togglePrint() {
      this.print = true;
      window.print();
    },
    remove() {
      this.pendingRemove = true;
      this.deletePayment({
        params: {
          paymentId: this.paymentId,
        },
      })
        .then(() => {
          this.action = '';
          this.$emit('removed', this.paymentId);
          this.$emit('close');
        })
        .finally(() => {
          this.pendingRemove = false;
        });
    },
    toDateOnly(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    select(action) {
      this.$emit('focus', true);
      this.action = action;
    },
    save() {
      this.pending = true;
      this.settlePayment({
        params: {
          paymentId: this.paymentId,
        },
        data: {
          bills: this.payersSettlement.flatMap((x) => x.settlement.bills),
          overpayments: this.payersSettlement.flatMap((x) => x.settlement.overpayments),
          refunds: this.payersSettlement.flatMap((x) => x.settlement.refunds),
          donations: this.payersSettlement.flatMap((x) => x.settlement.donations),
        },
      })
        .then(({ data }) => {
          this.action = '';
          this.$emit('focus', false);
          this.$emit('update', data);
          this.request();
        })
        .finally(() => {
          this.pending = false;
        });
    },
    resetSettlement() {
      if (!this.details) return;
      this.payersSettlement = this.payers.map((x) => ({
        ...x,
        settlement: {
          bills: this.details.bills.filter((b) => b.payerId === x.id),
          overpayments: this.details.overpayments.filter((b) => b.payerId === x.id),
          refunds: this.details.refunds.filter((b) => b.payerId === x.id),
          donations: this.details.donations.filter((b) => b.payerId === x.id),
        },
      }));
    },
    request() {
      this.details = this.payment;
      this.pending = true;
      this.getPayment({
        params: {
          paymentId: this.paymentId,
          query: {
            withTransfer: this.withTransfer,
          },
        },
      })
        .then(({ data }) => {
          this.details = data.payment;
          this.transfer = data.transfer;
          this.payers = data.payers;
          this.bills = data.bills;
          this.resetSettlement();
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.details = this.payment;
    this.request();
  },
};
</script>

<style lang="scss" scoped>

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.1;
  }

  .payer-section {
    font-size: 16px;
    font-weight: 600 !important;
  }

  .pending-details {
    height: 200px;
    background-color: #fbfbfb;
  }
</style>
