var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"operation-wrapper",class:{
    settlement: _vm.isSettlement,
    open: _vm.showDetails,
  },style:({
    'border-color': _vm.lightColor,
  })},[_c('div',{staticClass:"d-flex align-items-center audit-row",style:({
      'background-color': _vm.lightColor,
    }),on:{"click":function($event){_vm.isSettlement ? (_vm.showDetails = !_vm.showDetails) : null}}},[_c('div',{staticClass:"pl-1"},[_c('i',{class:_vm.typeInfo.icon,style:({ color: _vm.colors(_vm.typeInfo.color) })})]),_c('div',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.typeInfo.text)+" ")]),_c('div',{staticClass:"flex-grow-1"}),_c('div',{staticClass:"text-secondary text-right text-nowrap small pr-1"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.timestamp))+" ")])]),(_vm.showDetails)?_c('div',{staticClass:"p-2"},_vm._l((_vm.paymentPayers),function(p){return _c('div',{key:p.id},[_c('PaymentPayerSettlement',{attrs:{"payer":p,"bills":_vm.bills,"payment-id":_vm.paymentId,"currency":_vm.parsedDetails.currency,"bill-settlement":_vm.parsedDetails.bills.filter(x => x.payerId === p.id),"overpayment-settlement":_vm.parsedDetails.overpayments.filter(x => x.payerId === p.id),"refund-settlement":_vm.parsedDetails.refunds.filter(x => x.payerId === p.id),"donation-settlement":_vm.parsedDetails.donations.filter(x => x.payerId === p.id)}})],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }