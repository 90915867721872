<template>
  <div
    class="h-100 d-flex flex-column"
    style="overflow: hidden"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="page-header">
        <h1>
          {{ $t('payment.add') }}
        </h1>
      </div>
      <div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="cancel"
        >
          ×
        </button>
      </div>
    </div>
    <hr class="mt-1">
    <div class="row align-items-center">
      <StepSelect
        class="col-7"
        :page="page"
        :enabled="filledPages"
        :list="steps"
        @select="page = $event"
      />
      <div class="col-5 text-right position-relative">
        <button
          class="btn btn-primary btn-lg next-btn mr-1"
          :disabled="disabled || pending"
          @click="next()"
        >
          <span v-if="page !== 'settlement'">
            {{ $t('general.next') }}
            <i class="fas fa-arrow-right px-2" />
          </span>
          <span v-else>
            <span v-if="settled !== payment.paid">
              {{ $t('payment.addUnsettled') }}
            </span>
            <span v-else>
              {{ $t('general.add') }}
            </span>
            <i class="fas fa-check px-2" />
          </span>
        </button>
      </div>
    </div>
    <hr class="mb-2">
    <div
      v-if="page === 'details'"
      style="overflow: auto"
      class="h-100"
    >
      <PaymentDetailsForm
        :paid.sync="payment.paid"
        :date.sync="payment.date"
        :method.sync="payment.method"
        :currency.sync="payment.currency"
        :description.sync="payment.description"
        @billingScopeOpen="payment.billingScopeOpen = $event"
      />
    </div>
    <div
      v-else-if="page === 'settlement'"
      style="overflow: hidden"
    >
      <PaymentSettlementForm
        :payment="payment.paid"
        :currency="payment.currency"
        :payers.sync="payment.payers"
        :date="payment.date"
        @settled="settled = $event"
      />
    </div>
  </div>
</template>

<script>
import StepSelect from '@/components/bills/form/StepSelect';
import PaymentDetailsForm from '@/components/payments/PaymentDetailsForm';
import PaymentSettlementForm from '@/components/payments/PaymentSettlementForm';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data: () => ({
    page: 'details',
    payment: {
      date: moment().format('YYYY-MM-DD'),
      currency: 'pln',
      paid: null,
      method: 'Cash',
      description: '',
      billingScopeOpen: false,
      payers: [],
    },
    settled: 0,
    paymentId: null,
    bills: [],
    error: null,
    pending: false,
  }),
  components: {
    StepSelect,
    PaymentSettlementForm,
    PaymentDetailsForm,
  },
  computed: {
    ...mapGetters(['payeeId']),
    ...mapState({
      bankAccounts: (state) => state.bankAccount.bankAccounts,
    }),
    steps() {
      return [
        {
          key: 'details',
          text: this.$t('payment.details'),
        },
        {
          key: 'settlement',
          text: this.$t('payment.settlement'),
        },
      ];
    },
    disabled() {
      switch (this.page) {
        case 'details':
          return !this.payment.billingScopeOpen || !(this.payment.paid > 0);
        case 'settlement':
          return this.settled > this.payment.paid;
        default:
          return false;
      }
    },
    filledPages() {
      if (this.paymentId) return ['settlement'];
      if (this.page === 'settlement') return ['details', 'settlement'];
      return ['details'];
    },
  },
  methods: {
    ...mapActions([
      'addPayment',
      'settlePayment',
    ]),
    async save() {
      try {
        this.pending = true;
        if (!this.paymentId) {
          const { data } = await this.addPayment({
            data: {
              type: this.payment.method,
              timestamp: moment.utc(this.payment.date, 'YYYY-MM-DD').format(),
              title: this.payment.description,
              value: this.payment.paid,
              currency: this.payment.currency,
            },
          });

          this.paymentId = data.id;
        }

        await this.settlePayment({
          params: {
            paymentId: this.paymentId,
          },
          data: {
            bills: this.payment.payers.flatMap((x) => x.settlement.bills),
            overpayments: this.payment.payers.flatMap((x) => x.settlement.overpayments),
            refunds: this.payment.payers.flatMap((x) => x.settlement.refunds),
            donations: this.payment.payers.flatMap((x) => x.settlement.donations),
          },
        });

        this.cancel();
      } catch (e) {
        this.error = e;
      }
      this.pending = false;
    },
    next() {
      if (this.page === 'settlement') {
        this.save();
        return;
      }
      const index = this.steps.findIndex((x) => x.key === this.page);
      this.page = this.steps[index + 1].key;
    },
    cancel() {
      this.$router.push(`/payee/${this.payeeId}/payments`);
    },
  },
  created() {
    this.$emit('page', 'form');
  },
};
</script>

<style lang="scss" scoped>
  .close {
    font-size: 40px;
  }

  .overflow {
    overflow: visible;
  }

  h3 {
    font-weight: 500;
  }

  .next-btn {
    border-radius: 25px;
    padding-left: 30px;
    padding-right: 30x;
  }
  @media only screen and (max-width: 768px) {
    .overflow {
      overflow: auto;
    }
  }
</style>
